/** @jsx jsx */
import { FC } from "react";
import { jsx, Link, Container, Box, Flex } from "theme-ui";
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata";
import HeaderExternalLinks from "./header-external-links";

const Footer: FC = () => {
  const { siteTitle } = useSiteMetadata();

  return (
    <footer
      sx={{
        boxSizing: `border-box`,
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
        variant: `dividers.top`,
      }}
    >
      <Flex
        sx={{
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <HeaderExternalLinks />
        <Box
          sx={{
            textAlign: "end",
          }}
        >
          {siteTitle} &copy; {new Date().getFullYear()} <br />
          Made with ❤️
        </Box>
      </Flex>

      {/*       <div>
        <Link aria-label="RSS Feed" href="/rss.xml">
          RSS feed
        </Link>
      </div>
      <div>
        <Link
          aria-label="Link to the theme's GitHub repository"
          href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-minimal-blog"
        >
          Theme
        </Link>
        {` `}
        by
        {` `}
        <Link aria-label="Link to the theme author's website" href="https://www.lekoarts.de/en">
          LekoArts
        </Link>
      </div> */}
    </footer>
  );
};

export default Footer;
