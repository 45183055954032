import React from "react";

const Icon = ({ colorR = "#FFF", colorZ = "#FFFF00", colorCircle = "#3182CE", ...props }) => (
  <svg
    height="60"
    width="60"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={5}
    clipRule="evenodd"
    viewBox="-15 -10 195 185"
    {...props}
  >
    <ellipse cx={78.317} cy={81.784} fill={colorCircle} rx={88.317} ry={91.784} scale={1.2} />
    <g fillRule="nonzero">
      <path
        fill={colorR}
        d="M59.95 82.184l8.732.125c12.058.172 21.219-1.788 27.481-5.881s9.453-10.287 9.571-18.581c.139-9.734-2.566-16.921-8.114-21.559-5.548-4.639-13.555-7.033-24.02-7.183-4.781-.068-8.806.217-12.073.856-.638 5.888-1.114 19.765-1.426 41.633zm-.047 61.502l14.866.212c.802 2.068 1.189 4.062 1.162 5.981-.025 1.714-.388 2.909-1.091 3.584-.703.676-1.921 1.001-3.653.977l-56.913-.853c-.867-2.343-1.266-3.906-1.237-5.894.02-1.439.414-3.56 1.151-4.201s9.955-.317 11.619-.293l9.459.135c.691-9.521 1.317-33.955 1.879-73.302.3-21.045.241-36.336-.177-45.872l-21.624.348c-.799-2.343-.466-5.515-.437-7.503.02-1.439.656-2.514 1.393-3.155s10.528-.428 12.191-.404l44.804.64c21.137.301 36.135 3.395 44.992 9.281 8.858 5.886 13.199 15.033 13.021 27.44-.271 18.988-11.96 31.78-35.067 38.375 6.555 2.082 11.839 5.808 15.852 11.179s8.219 13.025 12.617 22.961c4.399 9.936 7.881 16.036 10.448 18.301s6.243 3.363 11.026 3.295c1.385 4.956 1.164 8.295-.662 10.018-1.827 1.722-6.31 2.532-13.448 2.43-8.247-.118-14.157-1.265-17.73-3.441-3.574-2.177-7.225-9.788-10.956-22.834-3.73-13.046-7.74-22.24-12.031-27.58-4.29-5.341-10.559-8.07-18.806-8.188a79.7 79.7 0 00-12.801.846c-.199 23.651-.148 39.49.153 47.517z"
      />
      <path
        fill={colorZ}
        d="M63.517 57.417c-1.345 12.618-11.674 24.161-10.556 24.191 1.083.03 5.116.66 7.558.7 3.09.05 8.163.001 8.163.001 12.058.172 21.219-1.788 27.481-5.881s9.453-10.287 9.571-18.581c.139-9.734-2.566-16.921-8.114-21.559-5.548-4.639-13.555-7.033-24.02-7.183-4.781-.068-12.027.904-12.027.904s1.253 5.159 1.917 11.746c.493 4.884.578 10.501.027 15.662zM52.961 81.608c.27-.434.301-.872.546-1.325.455-.844 1.106-1.703 1.568-2.423 5.492-8.558 7.781-14.621 8.442-20.443 1.55-13.643-1.944-27.408-1.944-27.408s-6.944 1.74-8.589-.863c-.561-.888-.953-2.002-1.221-3.207a9.531 9.531 0 018.628-11.564v-.002c5.908-.422 12.904-.287 12.904-.287 21.137.301 36.135 3.395 44.992 9.281 8.858 5.886 13.199 15.033 13.021 27.44-.271 18.988-11.96 31.78-35.067 38.375 6.555 2.082 11.839 5.808 15.852 11.179s8.219 13.025 12.617 22.961c4.399 9.936 7.881 16.036 10.448 18.301s6.243 3.363 11.026 3.295c1.385 4.956 1.164 8.295-.662 10.018-1.827 1.722-6.31 2.532-13.448 2.43-8.247-.118-14.157-1.265-17.73-3.441-3.574-2.177-7.225-9.788-10.956-22.834-3.73-13.046-7.74-22.24-12.031-27.58-4.29-5.341-10.559-8.07-18.806-8.188-4.297-.061-21.108 1.296-25.307 2.141-5.307 1.068-3.668-14.76-3.668-14.76s.3-3.118 1.804-3.273"
      />
    </g>
  </svg>
);

export default Icon;
