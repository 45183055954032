/** @jsx jsx */
import { Link } from "gatsby";
import { jsx, Flex, useColorMode } from "theme-ui";
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes";
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config";
import Logo from "../../../images/components/logo-rz1";

const HeaderTitle = () => {
  const { siteTitle } = useSiteMetadata();
  const { basePath } = useMinimalBlogConfig();
  const [mode] = useColorMode();

  return (
    <Link
      to={replaceSlashes(`/${basePath}`)}
      aria-label={`${siteTitle} - Back to home`}
      sx={{ color: `heading`, textDecoration: `none` }}
    >
      <Flex
        sx={{
          alignItems: "center",
        }}
      >
        <Logo />
        <h1
          sx={{
            ml: 3,
            my: 0,
            fontWeight: `medium`,
            fontSize: [3, 4],
            letterSpacing: "tight",
            color: mode === "dark" ? "#ffff00" : "primary",
          }}
        >
          Roberto Zaniboni
        </h1>
      </Flex>
    </Link>
  );
};

export default HeaderTitle;
