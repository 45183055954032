/** @jsx jsx */
import React from "react";
import { jsx, Link as TLink } from "theme-ui";
import { Link } from "gatsby";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config";
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes";
import LinkBar from "./link-bar";
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media";

type NavigationProps = {
  nav: {
    title: string;
    slug: string;
  }[];
};

const Navigation = ({ nav }: NavigationProps) => {
  const { basePath } = useMinimalBlogConfig();
  const index = useBreakpointIndex(3);

  const handleDisplay = (slug) => {
    // if (slug === "/cv/" && index == 0) return "none";
    if (slug === "/skills/" && index == 0) return "none";
    return "inline";
  };
  return (
    <React.Fragment>
      {nav && nav.length > 0 && (
        <nav
          sx={{
            "a:not(:last-of-type)": { mr: 3 },
            fontSize: [1, `18px`],
            "&.active": { color: `#FFDDDD` },
          }}
        >
          {nav.map((item) => (
            /*             <TLink
              key={item.slug}
              as={Link}
              sx={{ variant: `links.navbutton` }}
              activeClassName="active"
              to={replaceSlashes(`/${basePath}/${item.slug}`)}
            >
              {item.title}
            </TLink> */

            <LinkBar
              key={item.slug}
              to={replaceSlashes(`/${basePath}/${item.slug}`)}
              sx={{
                display: handleDisplay(item.slug),
              }}
            >
              {item.title}
            </LinkBar>
          ))}
        </nav>
      )}
    </React.Fragment>
  );
};

export default Navigation;
