/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui";
import { Link } from "gatsby";

const LinkBar = ({ to, ...props }) => {
  const [mode] = useColorMode();
  const isDark = mode === "dark" ? true : false;

  return (
    <Link
      to={to}
      variant="nav"
      {...props}
      activeClassName="active"
      sx={{
        display: "inlineBlock",
        position: "relative",
        transition: "all .35s ease-in-out",
        mr: [2, 2, 4, 4],
        fontWeight: "500",
        lineHeight: 1.3,
        fontFamily: "heading",
        py: 1,
        textDecoration: "none",
        color: (t) => `${t.colors.text}`,
        "&.active": {
          color: "primary",
          borderBottom: "0.25rem solid",
        },

        "&::after": {
          content: '""',
          display: "block",
          height: "0.3rem",
          left: "50%",
          position: "absolute",
          background: (t) => `${t.colors.primary}`, //isDark ? "white" : "rgb(0, 175, 240)",
          transition: "width 0.1s ease 0s, left 0.1s ease 0s",
          width: 0,
        },
        "&:hover": {
          color: (t) => `${t.colors.primary}`,
        },
        "&:hover:after": {
          width: "100%",
          left: 0,
        },
      }}
    />
  );
};

export default LinkBar;
